import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import './App.scss';
import Survey from './components/Survey';
import Request from './components/Request';
import { fb, auth, provider } from './firebase.js';
import SiteLayout from './components/Layout';

function App() {
  const fireUser = auth.currentUser;
  const [user, setUser] = useState(fireUser);

  const isAuthorized = (user) => {
    return user && (user.email.endsWith('@dksassociates.com') || user.email.endsWith('mindyzwan@gmail.com'));
  }

  useEffect(() => {
    const unsubscribe = fb.auth().onAuthStateChanged(user => { user ? setUser(user) : setUser(null) })
    return unsubscribe;
  }, [user])

  const LogOut = () => {
    const logOut = () => {
      auth.signOut()
        .then(() => {
          setUser(null)
        });
    }

    logOut()
      return (
        <React.Fragment>
            <h1>Thank you!</h1>
            <h2>You've been logged out.</h2>
            <Link className="button-link" to="">Return to Log In Page</Link>
        </ React.Fragment>
      )
  }

  const LogIn = () => {
    const login = () => {
      auth.signInWithPopup(provider)
        .then((result) => {
          const user = result.user;
          setUser(user);
        });
    }

    if (isAuthorized(user)) {
      return (<Redirect to="/request" />)
    }
    if (user) {
      return (<Redirect to="" />)
    }
    return (
      <React.Fragment>
      <h1>Log In</h1>
      <h2>DKS CX Survey Portal</h2>
          <button onClick={login}>Log In</button> 
          <p id="login-disclaimer">This site is for DKS employee use only. If you believe you've reached this page in error, please contact Jim Peters at DKS Associates for more information.</p>                 
    </ React.Fragment>
    )
  }

  const NotAuthorized = () => {
    if (isAuthorized(user)) {
      return <Redirect to="/request" />
    }
    if (user) {
      return (
        <React.Fragment>
          <h1>Not authorized to view this page</h1>
          <h2>Please log out and sign in with your DKS account.</h2>
          <Link className="button-link" to="/logout">Log Out</Link>
        </React.Fragment>
      )
    } 
    return (
      <Redirect to="/login" />
    )
  }

  return (
    <Router>
      <Switch>
        <Route path="/survey/:surveyID" component={ Survey } />
        {isAuthorized(user) && <Route path="/request" component={ Request }/>}
        {user && <Route path="/logout" component={ LogOut } />}
        {!user && <Route path="/login" component={ LogIn } />}
        <Route path="" component={ NotAuthorized } />
      </Switch>
    </Router>
  );
}

export default SiteLayout(App);
