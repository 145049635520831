import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    height: 300,
    margin: 30,
  },
  
  rail: {
    width: 8,
  }
});

const marks = [
  {
    value: 0,
    label: 'Unacceptable',
  },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  {
    value: 5,
    label: 'Needed Improvement',
  },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  {
    value: 10,
    label: 'Met Expectations',
  },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
	{
    value: 15,
    label: 'Exceeded Expectations',
  },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  {
    value: 20,
    label: 'Excellent',
	},
];

export default function SurveySlider({id, name, onChange}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Slider
          orientation="vertical"
          defaultValue={10}
          marks={marks}
          max={20}
          track={false}
          id={id}
          name={name}
          onChangeCommitted={onChange}
        />
      </div>
    </React.Fragment>
  );
}