import React, { useState, useReducer } from 'react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import { MdOpenInNew, MdSend } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import Alert from './Alert';

const Request = () => {
  const [numberOfContacts, setNumberOfContacts] = useState(1);
  const [isValid, setIsValid] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const contactFields = [...Array(numberOfContacts).keys()];
  const today = new Date();
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      projectNumber: "",
      projectName: "",
      office: "",
      pmName: "",
      pmEmail: "",
      picName: "",
      picEmail: "",
    }
  );

  const handleFormChange = event => {
    const { name, value } = event.target;
    setFormInput({ [name]: value });
  };

  const addMore = (event) => {
    event.preventDefault();
    setNumberOfContacts(numberOfContacts + 1);
  }

  const removeLast = (event) => {
    event.preventDefault();
    setNumberOfContacts(numberOfContacts - 1 || 1);
  }

  const handleBlur = (event) => {
    if (event.target.value === undefined) {
      setIsValid(false)
    }

    for (var field in formInput) {
      if (formInput[field] === "") {
        setIsValid(false)
        return;
      }
    };

    for (var i; i <= numberOfContacts; i += 1) {
      if (formInput['client' + i] === "" ||
        formInput['contactEmail' + i] === "" ||
        formInput['contactName' + i] === "") {
        setIsValid(false)
        return;
      }
    }

    if (formInput.planning !== 'on' && formInput.design !== 'on' && formInput.operations !== 'on') {
      setIsValid(false)
      return;
    }

    setIsValid(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isValid) {
      for (var i = 0; i <= numberOfContacts; i += 1) {
        let currentClient = formInput['client' + i];
        let currentContactEmail = formInput['contactEmail' + i];
        let currentContactName = formInput['contactName' + i];
        let surveyLink = 'https://cx-survey.dksassociates.com/survey/';

        db
          .collection('surveys')
          .add({
            sent: today,
            contact: {
              client_name: currentClient,
              email: currentContactEmail,
              name: currentContactName,
            },
            pic: {
              name: formInput.picName,
              email: formInput.pmEmail,
            },
            pm: {
              name: formInput.pmName,
              email: formInput.pmEmail,
            },
            project: {
              name: formInput.projectName,
              number: formInput.projectNumber,
              office: formInput.office,
              practice_areas: {
                is_planning: formInput.planning === 'on',
                is_design: formInput.design === 'on',
                is_operations: formInput.operations === 'on',
              }
            }
          })
          .then(function (docRef) {
            console.log("Document successfully written!", docRef.id);
            surveyLink += docRef.id;
            sendEmail(currentContactEmail, currentContactName, formInput.projectName, formInput.pmEmail, surveyLink, 'new_survey');
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
    }
    else {
      tripAlert("All fields are required")
    }
  }

  const sendEmail = (contactEmail, contactName, projectName, pmEmail, surveyLink, template) => {
    db
      .collection('mail')
      .add({
        to: [contactEmail],
        cc: [pmEmail],
        subject: 'DKS Client Survey: ' + projectName,
        template: {
          name: template,
          data: {
            contact_name: contactName,
            project_name: projectName,
            survey_link: surveyLink
          }
        }
      })
      .then(function (docRef) {
        console.log("Document successfully written!", docRef.id);
        tripAlert("Email(s) Sent")
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        tripAlert("Error - emails not sent")
      });
  }

  const sendReminderEmail = (event) => {
    event.preventDefault();
    db.collection('surveys')
      .doc(event.currentTarget.value)
      .get()
      .then(function (doc) {
        let survey = doc.data();
        if (window.confirm(`Are you sure you want to send a reminder email to ${survey.contact.name} for ${survey.project.name}? Press 'OK' to send the reminder email.`)) {
          let surveyLink = 'https://cx-survey.dksassociates.com/survey/' + doc.ref.id;
          sendEmail(survey.contact.email, survey.contact.name, survey.project.name, survey.pm.email, surveyLink, 'reminder');
          updateRemindedDate(doc.ref.id)
        };
      });
  }

  const updateRemindedDate = (id) => {
    db
      .collection('surveys')
      .doc(id)
      .set({
        reminded:
          today
      }, { merge: true });
  }

  const tripAlert = (msg) => {
    setAlertMessage(msg)
    setAlert(true);
    setTimeout(function () {
      setAlert(false);
    }, 2500);
  }

  let list =
  {
    projects: {
      names: [],
      numbers: []
    },
    employees: {
      names: [],
      emails: []
    },
    contacts: {
      names: [],
      emails: [],
      clients: []
    }
  }

  return (
    <main className="request">
      <FirestoreCollection
        path={'surveys'}
        // sort={'sent'}
        render={({ isLoading, data }) => {
          data.forEach(survey => {
            list.projects.names.push(survey.project.name)
            list.projects.numbers.push(survey.project.number)
            list.employees.names.push(survey.pm.name)
            list.employees.emails.push(survey.pm.email)
            list.employees.names.push(survey.pic.name)
            list.employees.emails.push(survey.pic.email)
            list.contacts.names.push(survey.contact.name)
            list.contacts.emails.push(survey.contact.email)
            list.contacts.clients.push(survey.contact.client_name)
          })

          list.projects.names = Array.from(new Set(list.projects.names))
          list.projects.numbers = Array.from(new Set(list.projects.numbers))
          list.employees.names = Array.from(new Set(list.employees.names))
          list.employees.emails = Array.from(new Set(list.employees.emails))
          list.contacts.names = Array.from(new Set(list.contacts.names))
          list.contacts.emails = Array.from(new Set(list.contacts.emails))
          list.contacts.clients = Array.from(new Set(list.contacts.clients))

          if (isLoading) {
            return <div>Still Loading...</div>;
          } else {
            return (
              <React.Fragment>
                <Link className="button-link" id='logout' to="/logout">Log Out</Link><br />
                <a id='logout' href="https://console.firebase.google.com/u/0/project/cx-survey-d7d5f/overview" target='_blank' rel="noopener noreferrer"><MdOpenInNew /> Admin Console</a>
                <a id='logout' href="https://datastudio.google.com/reporting/1953579c-a70c-4f2a-8304-ebe7d6cfcfc1" target='_blank' rel="noopener noreferrer"><MdOpenInNew /> Analysis Dashboard</a>
                <h1>Survey Request Form</h1>
                <fieldset>
                  <legend>Project Details</legend>
                  <fieldset>
                    <label htmlFor="project-number">Project Number</label>
                    <input list="project-number-select" required id="project-number" name="projectNumber" className="datalist" data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                    <datalist id="project-number-select">
                      {list.projects.numbers.map((number) => {
                        return (<option value={number} key={number} />)
                      })}
                    </datalist>

                    <label htmlFor="project-name">Project Name</label>
                    <input list="project-name-select" id="project-name" name="projectName" className="datalist" data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                    <datalist id="project-name-select">
                      {list.projects.names.map((name) => {
                        return (<option value={name} key={name} />)
                      })}
                    </datalist>

                    <label htmlFor="office">Office</label>
                    <select name="office" id="office" onChange={handleFormChange} onBlur={handleBlur}>
                      <option value="">&nbsp;&nbsp;-- Choose One -- &nbsp;&nbsp;</option>
                      <option value="Austin">Austin</option>
                      <option value="Bay Area">Bay Area</option>
                      <option value="Portland">Portland</option>
                      <option value="Sacramento">Sacramento</option>
                      <option value="Seattle">Seattle</option>
                      <option value="SoCal">SoCal</option>
                    </select>

                    <label>Practice Area</label>
                    <div id="practiceAreas">
                      <input
                        type="checkbox"
                        id="planning"
                        name="planning"
                        onChange={handleFormChange} />
                      <label htmlFor="planning" className="checkbox">Planning</label>

                      <input
                        type="checkbox"
                        id="operations"
                        name="operations"
                        onChange={handleFormChange} />
                      <label htmlFor="operations" className="checkbox">Operations</label>

                      <input
                        type="checkbox"
                        id="design"
                        name="design"
                        onChange={handleFormChange} />
                      <label htmlFor="design" className="checkbox">Design</label>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend>Project Manager</legend>
                    <label htmlFor="pmName">PM Name (First &amp; Last)</label>
                    <input list="pmName-select" id="pmName" name="pmName" className="datalist" data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                    <datalist id="pmName-select">
                      {list.employees.names.map((name) => {
                        return (<option value={name} key={name} />)
                      })}
                    </datalist>

                    <label htmlFor="pmEmail">PM Email</label>
                    <input list="pmEmail-select" id="pmEmail" name="pmEmail" className="datalist" data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                    <datalist id="pmEmail-select">
                      {list.employees.emails.map((email) => <option value={email} key={email} />)}
                    </datalist>
                  </fieldset>

                  <fieldset>
                    <legend>Principal In Charge</legend>
                    <label htmlFor="picName">PIC Name (First &amp; Last)</label>
                    <input list="picName-select" id="picName" name="picName" className="datalist" data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                    <datalist id="picName-select">
                      {list.employees.names.map((name) => {
                        return (<option value={name} key={name} />)
                      })}
                    </datalist>

                    <label htmlFor="picEmail">PIC Email</label>
                    <input list="picEmail-select" id="picEmail" name="picEmail" className="datalist" data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                    <datalist id="picEmail-select">
                      {list.employees.emails.map((email) => {
                        return (<option value={email} key={email} />)
                      })}
                    </datalist>
                  </fieldset>
                </fieldset>

                <fieldset>
                  <legend>Client Contact(s) Info</legend>
                  {contactFields.map((num) => (
                    <fieldset id={"contact" + num} key={"contact" + num}>
                      <legend>{"Contact #" + (num + 1)}</legend>

                      <label htmlFor={"client" + num}>Client</label>
                      <input list={"client-select" + num} key={"client" + num} id={"client" + num} name={"client" + num} className={"datalist name"} data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                      <datalist id={"client-select" + num}>
                        {list.contacts.clients.map((client) => {
                          return (<option value={client} key={"client-select" + num + client} />)
                        })}
                      </datalist>

                      <label htmlFor={"contactName" + num}>Contact Name (First &amp; Last)</label>
                      <input list={"contactName-select" + num} key={"contactName" + num} id={"contactName" + num} name={"contactName" + num} className={"datalist name"} data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                      <datalist id={"contactName-select" + num}>
                        {list.contacts.names.map((name) => {
                          return (<option value={name} key={"contactName-select" + num + name} />)
                        })}
                      </datalist>

                      <label htmlFor={"contactEmail" + num}>Contact Email</label>
                      <input list={"contactEmail-select" + (num)} key={"contactEmail" + num} id={"contactEmail" + num} name={"contactEmail" + num} className={"datalist email"} data-lpignore="true" onChange={handleFormChange} onBlur={handleBlur} />
                      <datalist id={"contactEmail-select" + num}>
                        {list.contacts.emails.map((email) => {
                          return (<option value={email} key={"contactEmail-select" + num + email} />)
                        })}
                      </datalist>
                    </fieldset>
                  ))}
                </fieldset>

                <fieldset>
                  <button onClick={addMore}>Add More</button>
                  <button onClick={removeLast}>Remove Last</button>
                </fieldset>
                {alert ? <Alert message={alertMessage} /> : null}
                <button onClick={handleSubmit}>Send Survey(s)</button>

                <section id="surveyList">
                  <h2>Pending Surveys</h2>
                  <p><MdOpenInNew /> - Open in New Window</p>
                  <p><MdSend /> - Send Reminder Email</p>
                  <br />
                  <ul>
                    {data.filter(x => !x.completed && !x.reminded).sort((x, y) => y.sent.toDate() - x.sent.toDate()).map(record => {
                      return (
                        <li key={record.id}>
                          <Link to={'/survey/' + record.id} target="_blank" title="Open survey">
                            <MdOpenInNew />
                          </Link>
                          <button className="email-reminder" title="Send reminder email" onClick={sendReminderEmail} value={record.id}>
                            <MdSend />
                          </button>
                          <b>{record.project.number}: {record.contact.name} ({record.contact.client_name})</b>
                          {record.sent ? " Sent: " + record.sent.toDate().toLocaleDateString() : null}
                          {record.reminded ? " Reminded: " + record.reminded.toDate().toLocaleDateString() : null}
                        </li>
                      )
                    })}
                    {data.filter(x => !x.completed && x.reminded).sort((x, y) => y.reminded.toDate() - x.reminded.toDate()).map(record => {
                      return (
                        <li key={record.id}>
                          <Link to={'/survey/' + record.id} target="_blank" title="Open survey">
                            <MdOpenInNew />
                          </Link>
                          <button 
                            className="email-reminder" 
                            title="Send reminder email" 
                            onClick={record.reminded.toDate() > today.getTime() - (24 * 60 * 60 * 1000) ? null : sendReminderEmail} 
                            value={record.id} 
                            style={record.reminded.toDate() > today.getTime() - (24 * 60 * 60 * 1000) ? { cursor: "not-allowed" } : null}
                          >
                            <MdSend />
                          </button>
                          <b>{record.project.number}: {record.contact.name} ({record.contact.client_name})</b>
                          {record.sent ? " Sent: " + record.sent.toDate().toLocaleDateString() : null}
                          {record.reminded ? " Reminded: " + record.reminded.toDate().toLocaleDateString() : null}
                        </li>
                      )
                    })}
                  </ul>
                </section>
              </React.Fragment>
            )
          }
        }}
      />
    </main>
  )
};

export default withFirestore(Request);