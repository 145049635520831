const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyBwYKbH8bfEAmJeGGhRvOs0ieDr1K04v3U",
  authDomain: "cx-survey-d7d5f.firebaseapp.com",
  databaseURL: "https://cx-survey-d7d5f.firebaseio.com",
  projectId: "cx-survey-d7d5f",
  storageBucket: "cx-survey-d7d5f.appspot.com",
  messagingSenderId: "454926281684",
  appId: "1:454926281684:web:50c924843e526a70574441",
  measurementId: "G-JS8T4M96FV"
};

const fb = firebase.initializeApp(config);
const db = fb.firestore();
const analytics = fb.analytics();
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  hd: 'dksassociates.com',
});
const auth = firebase.auth();
export { fb, db, provider, auth, analytics };
