import React, { useState, useReducer } from 'react';
import SurveySlider from './Slider';
import { useParams } from 'react-router-dom';
import { FirestoreDocument, withFirestore } from 'react-firestore';
import { db, analytics } from '../firebase';
import Alert from './Alert';

const Survey = () => {
  const { surveyID } = useParams();
  const today = new Date();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      nps: "",
      responsive: 10,
      quality: 10,
      timely: "",
      leading: "", 
      leadingWhy:"",
      comments: "",
    }
  );

  const tripAlert = (msg) => {
    setAlertMessage(msg)
    setAlert(true);
    setTimeout(function() {
      setAlert(false);
    }, 2500);
  }

  const handleBlur = (event) => {
    if (formInput.nps === "") {
      setIsValid(false)
      return;
    }

    if (formInput.timely === "") {
      setIsValid(false)
      return;
    }

    if (formInput.leading === "") {
      setIsValid(false)
      return;
    }
    setIsValid(true)
  }

  const handleFormChange = event => {
    let { name, value } = event.target;
    if (name === 'timely') { value *= 2 }
    if (name === 'nps') { value /= 1 }
    if (name === 'leading') { value /= 1 }
    setFormInput({ [name]: value });
  };

  const handleQualityChange = (event, value) => {
    setFormInput({ quality: value / 2 })
  }

  const handleResponsiveChange = (event, value) => {
    setFormInput({ responsive: value / 2 })
  }

  const handleSubmit = (data) => (event) => {
    event.preventDefault();

    if (isValid) {
      db
      .collection('surveys')
      .doc(surveyID)
      .set({
        completed:
          today,
        responses: {
          nps: formInput.nps,
          responsive: formInput.responsive,
          quality: formInput.quality,
          timely: formInput.timely,
          leading: formInput.leading,
          leadingWhy: formInput.leadingWhy,
          comments: formInput.comments,
        }
      }, { merge: true });
    
      let corpEmail = "kasey.delucia@dksassociates.com"
      if (formInput.nps <= 6 || formInput.responsive <= 6 || formInput.quality <= 6 || formInput.timely <= 6) corpEmail += ", plc@dksassociates.com"
      analytics.logEvent('complete_survey', { survey: surveyID })
    
      db
      .collection('mail')
      .add({
        to: [data.pm.email],
        cc: [data.pic.email, corpEmail],
        subject: 'New CX Survey Response: ' + data.project.number,
        template: {
          name: 'survey_response',
          data: {
            contact_name: data.contact.name,
            client_name: data.contact.client_name,
            project_number: data.project.number,
            project_name: data.project.name,
            survey_completed: today.toLocaleDateString(),
            nps: formInput.nps,
            responsive: formInput.responsive,
            quality: formInput.quality,
            timely: formInput.timely,
            leading: formInput.leading,
            leadingWhy: formInput.leadingWhy,
            comments: formInput.comments,
          }
        }
      })
      .then(function () {
        console.log("Document successfully written!")
      })
      .catch(function (error) {
        console.error("Error writing document: ", error)
      })
    } else {
      tripAlert("Please complete required fields")
    }
  }

  return (
    <main>
      <FirestoreDocument
        path={'surveys/' + surveyID}
        render={({ isLoading, data }) => {
          if (isLoading) {
            return <div>Loading...</div>;
          } else if (!data.project || !data.pm) {
            return <h1> No survey found </h1>
          } else if (data.completed) {
            return (
              <React.Fragment>
                <h1>Thank you!</h1>
                <h2>We appreciate your participation!</h2>
              </React.Fragment>
            )
          } else {
            analytics.logEvent('visited_survey_page', { survey: surveyID });
            return (
              <React.Fragment>
                <h1>Client Survey</h1>
                <h2>Project: {data.project.name}</h2>
                <h3>Project Manager: {data.pm.name}</h3>

                <fieldset className="survey-radio" id="nps">
                  <legend>
                  How likely is it that you would recommend DKS?
                  </legend>
                  <p id="scale-note"><i>With 1 being "not at all likely" and 10 being "extremely likely"</i></p>

                  <input type="radio" id="nps-1" name="nps" value="1" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-1">1</label>

                  <input type="radio" id="nps-2" name="nps" value="2" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-2">2</label>

                  <input type="radio" id="nps-3" name="nps" value="3" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-3">3</label>

                  <input type="radio" id="nps-4" name="nps" value="4" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-4">4</label>

                  <input type="radio" id="nps-5" name="nps" value="5" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-5">5</label>

                  <input type="radio" id="nps-6" name="nps" value="6" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-6">6</label>

                  <input type="radio" id="nps-7" name="nps" value="7" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-7">7</label>

                  <input type="radio" id="nps-8" name="nps" value="8" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-8">8</label>

                  <input type="radio" id="nps-9" name="nps" value="9" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-9">9</label>

                  <input type="radio" id="nps-10" name="nps" value="10" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="nps-10">10</label>
                </fieldset>

                <fieldset>
                  <label htmlFor="pm-response">How would you rate the DKS project manager on responsiveness?</label>
                  <SurveySlider id="pm-response" name="responsive" onChange={handleResponsiveChange} />
                </fieldset>

                <fieldset>
                  <label htmlFor="quality-work">What do you think of the overall quality of our technical work?</label>
                  <SurveySlider id="quality-work" name="quality" onChange={handleQualityChange} />
                </fieldset>

                <fieldset className="survey-radio" id="timely">
                  <legend>Were products and services delivered on time?</legend>

                  <input type="radio" id="timely-always" name="timely" value="5" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="timely-always">Always</label>

                  <input type="radio" id="timely-mostly" name="timely" value="4" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="timely-mostly">Mostly</label>

                  <input type="radio" id="timely-sometimes" name="timely" value="3" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="timely-sometimes">Sometimes</label>

                  <input type="radio" id="timely-rarely" name="timely" value="2" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="timely-rarely">Rarely</label>

                  <input type="radio" id="timely-never" name="timely" value="1" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="timely-never">Never</label>
                </fieldset>

                <fieldset className="survey-radio" id="leading">
                  <legend>
                  Do you consider DKS to be on the leading edge of the transportation industry?
                  </legend>
                  <p id="scale-note"><i>With 1 being "not on the leading edge at all" and 10 being "leaders in the industry"</i></p>

                  <input type="radio" id="leading-1" name="leading" value="1" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-1">1</label>

                  <input type="radio" id="leading-2" name="leading" value="2" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-2">2</label>

                  <input type="radio" id="leading-3" name="leading" value="3" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-3">3</label>

                  <input type="radio" id="leading-4" name="leading" value="4" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-4">4</label>

                  <input type="radio" id="leading-5" name="leading" value="5" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-5">5</label>

                  <input type="radio" id="leading-6" name="leading" value="6" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-6">6</label>

                  <input type="radio" id="leading-7" name="leading" value="7" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-7">7</label>

                  <input type="radio" id="leading-8" name="leading" value="8" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-8">8</label>

                  <input type="radio" id="leading-9" name="leading" value="9" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-9">9</label>

                  <input type="radio" id="leading-10" name="leading" value="10" onChange={handleFormChange} onBlur={handleBlur}/>
                  <label htmlFor="leading-10">10</label>
                </fieldset>

                <fieldset>
                  <label htmlFor="comments">Regarding your response to the above question, why or why not?</label>
                  <textarea id="comments" rows="10" name="leadingWhy" onChange={handleFormChange} />
                </fieldset>

                <fieldset>
                  <label htmlFor="comments">Additional Comments (Optional)</label>
                  <textarea id="comments" rows="10" name="comments" onChange={handleFormChange} />
                </fieldset>

                {alert ? <Alert message={ alertMessage } /> : null}
                <button onClick={handleSubmit(data)}>Submit</button>
              </React.Fragment>
            );
          }
        }}
      />
    </main>
  )
};

export default withFirestore(Survey);