import React from 'react';

function SiteLayout(Component) {
  class Layout extends React.Component {
    render() {
      return (
        <React.Fragment>
          <header>
            <img src={process.env.PUBLIC_URL + "/header.png"} alt="" />
          </header>
          <main>
            <Component />
          </main>
          <footer>
            <p><strong>Shaping a smarter transportation experience</strong><sup>&trade;</sup><span>&bull;</span>An employee-owned company<span>&bull;</span><strong><a href="https://www.dksassociates.com/">DKSAssociates.com</a></strong></p>
          </footer>
        </ React.Fragment>
      )
    }
  }
  return Layout;
}

export default SiteLayout;